/* chartist overide */
.chartist {
    height: 240px;
    width: 100%;
    .ct-label {
        color: @text_secondary_color;
        fill: @text_secondary_color;
        font-size: 14px;
    }
    &-labels-inside {
        .ct-label {
            color: @white;
            fill: @white;
        }
    }
}
.ct-line {
    stroke-width: 2px;
}
.ct-point {
    stroke-width: 8px;
}



@chartist_series_a:      #1f77b4;
@chartist_series_b:      #ff7f0e;
@chartist_series_c:      #2ca02c;
@chartist_series_d:      #d62728;
@chartist_series_e:      #9467bd;
@chartist_series_f:      #8c564b;
@chartist_series_g:      #e377c2;
@chartist_series_h:      #7f7f7f;
@chartist_series_i:      #bcbd22;
@chartist_series_j:      #17becf;
@chartist_series_k:      #eacf7d;
@chartist_series_l:      #86797d;
@chartist_series_m:      #b2c326;
@chartist_series_n:      #6188e2;
@chartist_series_o:      #a748ca;

.ct-series-a {
    .ct-bar,
    .ct-line,
    .ct-point,
    .ct-slice-donut {
        stroke: @chartist_series_a;
    }
    .ct-area,
    .ct-slice-pie {
        fill: @chartist_series_a;
    }
}
.ct-series-b {
    .ct-bar,
    .ct-line,
    .ct-point,
    .ct-slice-donut {
        stroke: @chartist_series_b;
    }
    .ct-area,
    .ct-slice-pie {
        fill: @chartist_series_b;
    }
}
.ct-series-c {
    .ct-bar,
    .ct-line,
    .ct-point,
    .ct-slice-donut {
        stroke: @chartist_series_c;
    }
    .ct-area,
    .ct-slice-pie {
        fill: @chartist_series_c;
    }
}
.ct-series-d {
    .ct-bar,
    .ct-line,
    .ct-point,
    .ct-slice-donut {
        stroke: @chartist_series_d;
    }
    .ct-area,
    .ct-slice-pie {
        fill: @chartist_series_d;
    }
}
.ct-series-e {
    .ct-bar,
    .ct-line,
    .ct-point,
    .ct-slice-donut {
        stroke: @chartist_series_e;
    }
    .ct-area,
    .ct-slice-pie {
        fill: @chartist_series_e;
    }
}
.ct-series-f {
    .ct-bar,
    .ct-line,
    .ct-point,
    .ct-slice-donut {
        stroke: @chartist_series_f;
    }
    .ct-area,
    .ct-slice-pie {
        fill: @chartist_series_f;
    }
}
.ct-series-g {
    .ct-bar,
    .ct-line,
    .ct-point,
    .ct-slice-donut {
        stroke: @chartist_series_g;
    }
    .ct-area,
    .ct-slice-pie {
        fill: @chartist_series_g;
    }
}
.ct-series-h {
    .ct-bar,
    .ct-line,
    .ct-point,
    .ct-slice-donut {
        stroke: @chartist_series_h;
    }
    .ct-area,
    .ct-slice-pie {
        fill: @chartist_series_h;
    }
}
.ct-series-i {
    .ct-bar,
    .ct-line,
    .ct-point,
    .ct-slice-donut {
        stroke: @chartist_series_i;
    }
    .ct-area,
    .ct-slice-pie {
        fill: @chartist_series_i;
    }
}
.ct-series-j {
    .ct-bar,
    .ct-line,
    .ct-point,
    .ct-slice-donut {
        stroke: @chartist_series_j;
    }
    .ct-area,
    .ct-slice-pie {
        fill: @chartist_series_j;
    }
}
.ct-series-k {
    .ct-bar,
    .ct-line,
    .ct-point,
    .ct-slice-donut {
        stroke: @chartist_series_k;
    }
    .ct-area,
    .ct-slice-pie {
        fill: @chartist_series_k;
    }
}
.ct-series-l {
    .ct-bar,
    .ct-line,
    .ct-point,
    .ct-slice-donut {
        stroke: @chartist_series_l;
    }
    .ct-area,
    .ct-slice-pie {
        fill: @chartist_series_l;
    }
}
.ct-series-m {
    .ct-bar,
    .ct-line,
    .ct-point,
    .ct-slice-donut {
        stroke: @chartist_series_m;
    }
    .ct-area,
    .ct-slice-pie {
        fill: @chartist_series_m;
    }
}
.ct-series-n {
    .ct-bar,
    .ct-line,
    .ct-point,
    .ct-slice-donut {
        stroke: @chartist_series_n;
    }
    .ct-area,
    .ct-slice-pie {
        fill: @chartist_series_n;
    }
}
.ct-series-o {
    .ct-bar,
    .ct-line,
    .ct-point,
    .ct-slice-donut {
        stroke: @chartist_series_o;
    }
    .ct-area,
    .ct-slice-pie {
        fill: @chartist_series_o;
    }
}