// Material Design Icons
// http://google.github.io/material-design-icons/
@material_icons_directory:   '../icons/material-design-icons/';


@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url("@{material_icons_directory}MaterialIcons-Regular.eot"); /* For IE6-8 */
    src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url("@{material_icons_directory}MaterialIcons-Regular.woff2") format('woff2'),
    url("@{material_icons_directory}MaterialIcons-Regular.woff") format('woff'),
    url("@{material_icons_directory}MaterialIcons-Regular.ttf") format('truetype');
}
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 18px;  /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    vertical-align: -4px;
    color: rgba(0, 0, 0, 0.54);
    &.md-inactive {
        color: rgba(0, 0, 0, 0.26);
    }
    &.md-24 {
        font-size: 24px;
        vertical-align: -8px;
    }
    &.md-36 {
        font-size: 36px;
        vertical-align: -16px;
    }
    &.md-48 {
        font-size: 48px;
        vertical-align: -24px;
    }
    &.md-light {
        color: rgba(255, 255, 255, 1);
        &.md-inactive {
            color: rgba(255, 255, 255, 0.3);
        }
    }
}
