/* Ion.RangeSlider, Modern Skin */

.irs {
    position: relative;
    display: block;
    -webkit-touch-callout: none;
    user-select: none;
    height: 55px;
    &-line {
        height: 2px;
        top: 33px;
        background: #EEE;
        position: relative;
        display: block;
        overflow: hidden;
        outline: none !important;
        &-left,
        &-mid,
        &-right {
            position: absolute;
            display: block;
            top: 0;
        }
        &-left {
            height: 8px;
            left: 0;
            width: 11%;
        }
        &-mid {
            height: 8px;
            left: 9%;
            width: 82%;
        }
        &-right {
            height: 8px;
            right: 0;
            width: 11%;
        }
    }
    &-bar {
        height: 2px;
        top: 33px;
        background: @input_on_color;
        position: absolute;
        display: block;
        left: 0; width: 0;
        &-edge {
            height: 2px;
            width: 6px;
            border-right: 0;
            background: @input_on_color;
            position: absolute;
            display: block;
            top: 33px;
            left: 0;
        }
    }
    &-shadow {
        height: 4px;
        top: 32px;
        background: #000;
        opacity: 0.4;
        position: absolute;
        display: none;
        left: 0;
        width: 0;
    }
    &-slider {
        top: 28px;
        width: 12px;
        height: 12px;
        background: @input_on_color;
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        display: block;
        z-index: 1;
        &.state_hover,
        &:hover {
            background: @input_on_color;
        }
        &.type_last {
            z-index: 2;
        }
    }
    &-min,
    &-max {
        color: @text_secondary_color;
        font-size: 12px;
        line-height: 16px;
        text-shadow: none;
        top: 0;
        padding: 1px 4px;
        background: rgba(0,0,0,0.08);
        border-radius: 2px;
        display: block;
        cursor: default;
        position: absolute;
    }
    &-min {
        left: 0;
    }
    &-max {
        right: 0;
    }
    &-from,
    &-to,
    &-single {
        color: #fff;
        font-size: 12px;
        line-height: 16px;
        text-shadow: none;
        padding: 1px 4px;
        background: @input_on_color;
        border-radius: 2px;
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        cursor: default;
        white-space: nowrap;
        &:after {
            content: '';
            position: absolute;
            bottom: -8px;
            left: 50%;
            margin-left: -4px;
            display: block;
            border: 4px solid transparent;
            border-top-color: @input_on_color;
        }
    }
    &-with-grid {
        height: 65px;
    }
    &-grid {
        position: absolute;
        display: none;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20px;
        &-pol {
            opacity: 0.5;
            position: absolute;
            top: 0; left: 0;
            width: 1px;
            height: 8px;
            background: @text_secondary_color;
            &.small {
                background: @text_secondary_color;
                height: 4px;
            }
        }
        &-text {
            position: absolute;
            bottom: 0;
            left: 0;
            white-space: nowrap;
            text-align: center;
            font-size: 9px;
            line-height: 9px;
            padding: 0 3px;
            color: @text_secondary_color;
        }
        .irs-with-grid & {
            display: block;
        }
    }
    &-disable-mask {
        position: absolute;
        display: block;
        top: 0;
        left: -1%;
        width: 102%;
        height: 100%;
        cursor: default;
        background: rgba(0,0,0,0.0);
        z-index: 2;
    }
    &-disabled {
        opacity: 0.4;
    }
    &-hidden-input {
        position: absolute !important;
        display: block !important;
        top: 0 !important;
        left: 0 !important;
        width: 0 !important;
        height: 0 !important;
        font-size: 0 !important;
        line-height: 0 !important;
        padding: 0 !important;
        margin: 0 !important;
        outline: none !important;
        z-index: -9999 !important;
        background: none !important;
        border-style: solid !important;
        border-color: transparent !important;
    }
}

.lt-ie9 {
    .irs-shadow {
        filter: alpha(opacity=30);
    }
    .irs-min,
    .irs-max {
        background: #ccc;
    }
    .irs-from,
    .irs-to,
    .irs-single {
        background: #999;
    }
    .irs-disable-mask {
        background: #000;
        filter: alpha(opacity=0);
        cursor: not-allowed;
    }
}

