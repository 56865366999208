/*
table font size
*/

.uk-table {
  tr{
    th {
      font-size: 1.1em;

    }
    td{
      font-size:1.1em;
    a{
      font-size:1em;

    }
      p{
        font-size:1em;

      }
    }
  }

  td {
    font-size: 1.1em;
    input.input{
      font-size:1.1em;
      min-width:50px;
      max-width:60px;

    }

  }
  td.input{
font-size:1.1em;
    min-width:50px;
    max-width:100px;

  }

}

/*
li with a p font size
*/

li {
  p {
    font-size: 1.1em;

  }
  a{
    font-size: 1.1em;

  }

}

/*
  form font size
*/
form {
  label {
    font-size: 1.1em;

  }
  select {
    font-size: 1.1em;

  }
//  input {
//    font-size: 1.2em;
//    min-width:50px;
//    max-width:100px;
//
//  }


}

.show-on-print{
  display:none;

}
img.cru-employer-view{
  //background:#fff;
  width:160px;
  margin:0 auto;
  position:absolute;
  margin-top:0px;
//  border-radius:50%;

}
img.cru-login{
  background-color:#fff;
  width:80px;
  padding:20px auto;
  position:absolute;
  margin-top:-217px;
  margin-left:11px;
  border-radius:50%;

}

.suggested-classes{
  background:rgba(0, 0, 0, 0.085);
  padding:20px;

}

@media only screen and (max-width: 900px) {
img.cru-employer-view{
  display:none;

}
}
.printable-report-table{
  display:none;

}
.right-half .left-half{
  height:100%;
}
//
//
//.hide{
//  display:none !important;
//}
.md-input-wrapper{
  width:90%;
  margin-right:30px;
  label{

    margin-top:10px;
    margin-bottom:10px;
  }

  border:1px solid rgba(0, 0, 0, 0.12);


}
.parsley-errors-list{
  color:red;
}
.button.active{
  border-width: 3px;
  border-color: #ff6d00;
  color: #ff6d00;
}
