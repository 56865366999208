/* top bar */
.md-top-bar {
    .md-top-bar-actions-left {
        float: left;
        padding-left: 16px;
        .md-top-bar-checkbox {
            padding-top: 10px;
        }
    }
    .md-top-bar-actions-right {
        float: right;
        padding-right: 16px;
        .md-top-bar-icons {
            margin-top: 5px;
        }
    }
}