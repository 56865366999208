/* switchery */

.switchery {
    background-color: @white;
    border-radius: 20px;
    cursor: pointer;
    display: inline-block;
    height: 14px;
    position: relative;
    vertical-align: middle;
    width: 38px;
    user-select: none;
    box-sizing: content-box;
    background-clip: content-box;
    transition: background-color 800ms ease-out !important;
    box-shadow: none !important;
    margin: 6px 2px;
    & > small {
        background: @white;
        border-radius: 50%;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.55);
        height: 20px;
        position: absolute;
        top: 0;
        width: 20px;
        margin-top: -3px;
        transition: background 200ms @md_easing, left 0.2s @md_easing !important;
    }
    &-small {
        width: 32px;
        height: 9px;
        margin: 4px 1px;
        > small {
            height: 16px;
            width: 16px;
            top: -1px;
        }
    }
    &-large {
        width: 48px;
        > small {
            height: 24px;
            width: 24px;
            top: -3px;
        }
    }
}