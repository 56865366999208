/* vector maps */
.mapTooltip {
    background: @md-color-grey-700;
    color: @white;
    font-size: 14px;
    padding: 6px 16px;
    line-height: 18px;
    text-shadow: none;
    position: fixed;
    border-radius: 4px;
    z-index: 1000;
    max-width: 200px;
    display:none;
}
.vm_wrapper {
    max-width: 100%;
    margin: 0 auto;
}
.plotLegend {
    margin-top: 24px;
    padding: 16px 16px 8px;
}