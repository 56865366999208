/* print stylesheet */
@media print {

  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important; // Black prints faster: h5bp.com/s
    box-shadow: none !important;
    text-shadow: none !important;

  }

  a,
  a:visited {
    text-decoration: underline;
  }

  //    a[href]:after {
  //        content: " (" attr(href) ")";
  //    }
  a[href]:after {
    content: none !important;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group; // h5bp.com/t
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  .uk-table {
    border-collapse: collapse !important;
    td,
    th {
      background-color: #fff !important;
    }
  }

  .md-btn-group {
    display: none;
  }

  .uk-table-bordered {
    th,
    td {
      border: 1px solid #ddd !important;
    }
  }

  #sidebar_main,
  #sidebar_secondary,
  #header_main,
  .header_double_height:before,
  .hidden-print,
  .md-fab-wrapper {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    margin: 0 !important;
  }

  .sidebar_main_active #header_main,
  .sidebar_main_active #top_bar {
    display: none !important;
    left: 0 !important;
  }

  body {
    overflow-y: visible !important;
    background: #fff;
    //        padding: 20px !important;
    padding: 0px !important;
    margin: 0px !important;
    &:after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      display: block;
      z-index: 9999;
      height:auto;


    }
  }

  .uk-table th {
    font-size: 10px !important;

  }

  .uk-table td {
    font-size: 10px !important;
    padding: 0px;
    a {
      font-size: 10px !important;
      padding: 0px;

    }
    p {
      font-size: 10px !important;
      padding: 0px;

    }
  }

  .uk-table th {
    font-size: 10px;
    padding: 0px;
  }

  #sidebar_main {
    left: -@sidebar_main_width !important;
  }

  .main-print {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    left: 0 !important;
    top: 0 !important;
  }

  .md-card-single {
    margin: 0 !important;
  }

  #page_content {
    margin: 0 !important;
    overflow-y: visible !important;
    height: auto !important;
    transition: none !important;
    &:before {
      display: none !important;

    }
  }

  .reset-print {
    left: 0 !important;
    right: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }

  .md-card-content {
    padding: 0;
    height: auto !important;
  }

  .user_content {
    padding: 0;
    margin: 0;
  }

  //.user_heading_avatar img {
  //    width: 100px;
  //    height: 100px;
  //    position: relative;
  //    left: 20px;
  //}
  span.hidden-id {
    display: block;
    font-size: 40px;
    text-align: right;
  }

  .uk-text-truncate.card-name {
    color: #000000;
    position: absolute;
    min-height:50px;
    max-height:50px;
    //top: 150px;
  }

  .md-list.card-list > li {
    border: none;
  }

  .left-id-content {
    float:left;
    width:50%;
    //padding-left: 10px;
    //position: relative;
    margin-top: -70px;
  }

  .right-id-content {
    float: right;
    width:50%;
    //position: absolute;
    margin-top: -70px;
    //left: 300px;
  }

  .user_heading{
    border-bottom: 0px !important;
  }

  .page_content_inner {
    border: 5px solid black;
  }

  .id-card {
    padding-top: 20px;
    border: 5px solid black;
    border-radius: 20px;
    width: 550px;
    height: 360px;
  }

  .uk-sticky-placeholder {
    display: none;

  }

  #sidebar_main {
    display: none;

  }

  .show-on-print {
    display: block;


  }

  /*
  closing brace for the print media queries
  */
  img.cru-employer-view {
    display: none;

  }

  //.user_heading-employer{
  //  //max-height: 100px;
  //
  //}

  .printable-report-table {
    display: block;
    width: 100%;

  }

  .md-card {
    margin: 0;
    padding: 0;
  }

  .user_heading_content {
    margin: 0 0 0 0;
    padding: 0;
    z-index: -1000;
    max-height: 20px;
    background: transparent;
  }
  //
  //.user_heading-employer {
  //  margin: 0 0 0 0;
  //  padding: 0;
  //  //border: none;
  //  //max-height: 20px;
  //  //border-bottom: none !important;
  //  //z-index: -1000;
  //  //background: transparent;
  //}

  .user_heading-student .user_heading-employer {
    padding: 0;
    margin-bottom:-140px;
    //border: none;
    max-height: 20px !important;
    border-bottom:0px !important;
    //z-index: -1000;
    background: transparent !important;
  }
  .card-heading{
    margin-bottom:-140px;
    max-height:20px !important;
    border:0px !important;
    background:transparent !important;
  }

  .uk-grid {
    //margin-top: -20px;
    padding: 0;
  }

  .uk-margin-medium-top .uk-margin-large-bottom {
    margin: 0;
    padding: 0;
  }

  .user_profile_tabs {
    margin: 0;
    padding: 0;
  }

  .left-id-content {
    //margin: -80px 0 0 0;
    //z-index: 1000;
    //padding: 0;
  }

  .right-id-content {
    //margin: -80px 0 0 0;
    //padding: 0;
  }

  .uk-width-large-1-2 {
    margin: 0;
    padding: 0;
  }

  #user_profile_tabs_content {
    margin: 0;
    padding: 0;
  }

  .uk-margin {
    margin: 0;
    padding: 0;
  }


  .user_content {
    margin: 0 !important;
    padding: 0 !important;
    //max-height: 100%;
  }

  .uk-tab {
    display: none;
  }

  .md-list > li {
    border-bottom: none;
  }

  .md-list-content {
    line-height: 10px;
  }

  .uk-sticky-placeholder {
    margin: 0;
    padding: 0;
  }

  .id-card {
    border-radius: 0;
    max-height: 180px;
    max-width: 400px;
    margin: 0;
    h4 {
      margin-top: -10px;
    }
    p {
      font-size: 10px;
    }

    .right-half {

      margin-left: 0;

    }


  }

  .full_width_in_card {
    margin: -10px;
  }

  .heading_b {
    margin: -30px;
  }

  .hide-not-printing{
    display:block;
  }

  .backside{
    -ms-transform: rotate(180deg); /* IE 9 */
    -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
    transform: rotate(180deg);
  }
}

.hide-not-printing{
  display:none;
}
@media print {
  .non-printable {
    display: none;
  }

  .printable {
    display: block;
  }
}
@media print{
  .md-card{
    page-break-inside: avoid;

  }
  .non-printable{
    display:none;

  }
  .printable{
    display:block;

  }
  .printable .uk-table td{
    font-size:12px;
  }
  .printable .uk-table td a{
    font-size:12px;
  }
  .printable .uk-table td a{
    font-size:12px;
  }
  .printable .uk-table th{
    font-size:12px;

  }
}
@media print{
  html, body {
    overflow:hidden;
    // height: auto;
    font-size:12px;
    width:100%;

  }
  .md-card{
    margin:0 0 0 0;
    page-break-inside: avoid;
    /*page-break-before: avoid;*/
    /*page-break-after: avoid;*/

    font-size:12px;

  }
  .employer-block{
    page-break-inside: avoid;
  }
  .student-block{
    page-break-inside: avoid;
  }
  .non-printable{
    display:none !important;

  }
  .printable{
    display:block;

  }
  .printable .uk-table td{
    font-size:12px;
  }
  .printable .uk-table td a{
    font-size:12px;
  }
  .printable .uk-table td a{
    font-size:12px;
  }
  .printable .uk-table th{
    font-size:12px;

  }
  .show-on-print{
    line-height:1em;

  }
  .md-input{
    height:1em;
  }
  .user_heading-employer{
    margin-bottom:10px;
  }
  .user_content{
    margin-top:250px;
  }
  .uk-table th{
    border-bottom: 1px solid black;
  }
  .uk-table td{
    border-bottom: 0;
    margin: 2px;
  }
  .checkbox {
    width:20px;
    height:20px;
    border: 1px solid #000;
    display: inline-block;
  }

  /* This is what simulates a checkmark icon */
  .checkbox.checked:after {
    content: '';
    display: block;
    width: 4px;
    height: 7px;

    /* "Center" the checkmark */
    position:relative;
    top:4px;
    left:7px;

    border: solid #000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
.reason input{
  width:30%;
  border-color: black;
  border-style: solid;
  border-width: 1px;
}
.last input{
  width:70%;
  border-color: black;
  border-style: solid;
  border-width: 1px;
}
.first input{
  width:70%;
  border-color: black;
  border-style: solid;
  border-width: 1px;
}
.ssn input{
  width:60%;

  border-color: black;
  border-style: solid;
  border-width: 1px;
}
.home input{
  width:85%;
  border-color: black;
  border-style: solid;
  border-width: 1px;
}
.lastWorked input{
  width:70%;
  border-color: black;
  border-style: solid;
  border-width: 1px;
}
.outWork input{
  width:70%;
  border-color: black;
  border-style: solid;
  border-width: 1px;
}

@media print {

  html.print-student * {
     margin-bottom: 0!important;
     padding-bottom: 0!important;
  }

    .moveup {
      margin-top: -40px!important;
    }

  .reason input{
    width:30%;
    border-color: black;
    border-style: solid;
    border-width: 1px;
  }
  .last input{
    width:50%;
    border-color: black;
    border-style: solid;
    border-width: 1px;
  }
  .first input{
    width:50%;
    border-color: black;
    border-style: solid;
    border-width: 1px;
  }
  .ssn input{
    width:30%;

    border-color: black;
    border-style: solid;
    border-width: 1px;
  }
  .home input{
    width:85%;
    border-color: black;
    border-style: solid;
    border-width: 1px;
  }
  .lastWorked input{
    width:70%;
    border-color: black;
    border-style: solid;
    border-width: 1px;
  }
  .outWork input{
    width:70%;
    border-color: black;
    border-style: solid;
    border-width: 1px;
  }
}
@media screen {
  div.divFooter {
    display: none;
  }
}
@media print {
  div.divFooter {
    position: fixed;
    bottom: 20px;
    margin: auto;
  }
}
