/* Magnific Popup CSS */

////////////////////////
//
// Contents:
//
// 1. Default Settings
// 2. General styles
//    - Transluscent overlay
//    - Containers, wrappers
//    - Cursors
//    - Helper classes
// 3. Appearance
//    - Preloader & text that displays error messages
//    - CSS reset for buttons
//    - Close icon
//    - "1 of X" counter
//    - Navigation (left/right) arrows
//    - Iframe content type styles
//    - Image content type styles
//    - Media query where size of arrows is reduced
//    - IE7 support
//
////////////////////////



////////////////////////
// 1. Default Settings
////////////////////////

@mfp-overlay-color:                   #000;
@mfp-overlay-opacity:                 0.3;
@mfp-shadow:                          0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22); // shadow on image or iframe
@mfp-popup-padding-left:              8px; // Padding from left and from right side
@mfp-popup-padding-left-mobile:       6px; // Same as above, but is applied when width of window is less than 800px

@mfp-z-index-base:                    10200; // Base z-index of popup
@mfp-controls-opacity:                0.65;
@mfp-controls-color:                  #FFF;
@mfp-controls-border-color:           #3F3F3F;
@mfp-inner-close-icon-color:          #333;
@mfp-controls-text-color:             @white; // Color of preloader and "1 of X" indicator
@mfp-controls-text-color-hover:       @white;

// Iframe-type options
@mfp-iframe-padding-top:              40px;
@mfp-iframe-background:               #000;
@mfp-iframe-max-width:                900px;
@mfp-iframe-ratio:                    9/16;

// Image-type options
@mfp-image-background:                #444;
@mfp-image-padding-top:               40px;
@mfp-image-padding-bottom:            40px;

// Image caption options
@mfp-caption-title-color:             #F3F3F3;
@mfp-caption-subtitle-color:          #BDBDBD;


////////////////////////
// 2. General styles
////////////////////////

// Transluscent overlay
.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: @mfp-z-index-base + 2;
    overflow: hidden;
    position: fixed;
    background: @mfp-overlay-color;
    opacity: @mfp-overlay-opacity;
}

// Wrapper for popup
.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: @mfp-z-index-base + 3;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden; // fixes webkit bug that can cause "false" scrollbar
}

// Root container
.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 @mfp-popup-padding-left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

// Vertical centerer helper
.mfp-container {
    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
}

// Remove vertical centering when popup has class `mfp-align-top`
.mfp-align-top {
    .mfp-container {
        &:before {
            display: none;
        }
    }
}

// Popup content holder
.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: @mfp-z-index-base + 5;
}
.mfp-inline-holder,
.mfp-ajax-holder {
    .mfp-content {
        width: 100%;
        cursor: auto;
    }
}

// Cursors
.mfp-ajax-cur {
    cursor: progress;
}
.mfp-zoom-out-cur {
    &, .mfp-image-holder .mfp-close {
        cursor: -moz-zoom-out;
        cursor: -webkit-zoom-out;
        cursor: zoom-out;
    }
}
.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}
.mfp-auto-cursor {
    .mfp-content {
        cursor: auto;
    }
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
    user-select: none;
}

// Hide the image during the loading
.mfp-loading {
    &.mfp-figure {
        display: none;
    }
}

// Helper class that hides stuff
.mfp-hide {
    display: none !important;
}


////////////////////////
// 3. Appearance
////////////////////////

// Preloader and text that displays error messages
.mfp-preloader {
    color: @mfp-controls-text-color;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: @mfp-z-index-base + 4;
    a {
        color: @mfp-controls-text-color;
        &:hover {
            color: @mfp-controls-text-color-hover;
        }
    }
}

// Hide preloader when content successfully loaded
.mfp-s-ready {
    .mfp-preloader {
        display: none;
    }
}

// Hide content when it was not loaded
.mfp-s-error {
    .mfp-content {
        display: none;
    }
}

// CSS-reset for buttons
button {
    &.mfp-close,
    &.mfp-arrow {
        overflow: visible;
        cursor: pointer;
        background: transparent;
        border: 0;
        -webkit-appearance: none;
        display: block;
        outline: none;
        padding: 0;
        z-index: @mfp-z-index-base + 6;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    &::-moz-focus-inner {
        padding: 0;
        border: 0
    }
}


// Close icon
.mfp-close {
    width: 24px;
    height: 24px;
    line-height: 24px;

    position: absolute;
    right: 0;
    top: 0;
    margin-top: 8px;

    &:after {
        content: '\e5cd';
        font-family: "Material Icons";
        font-size: 24px;
        color: @white;
    }

}
.mfp-close-btn-in {
    .mfp-close {
        color: @text_primary_color;
    }
}
.mfp-image-holder,
.mfp-iframe-holder {
    .mfp-close {
        color: @mfp-controls-color;
        right: -6px;
        text-align: right;
        padding-right: 6px;
        width: 100%;
    }
}

// "1 of X" counter
.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: @mfp-controls-text-color;
    font-size: 13px;
    line-height: 24px;
    white-space: nowrap;
}

// Navigation arrows
     .mfp-arrow {
         position: absolute;
         margin: 0;
         top: 50%;
         margin-top: -50px;
         padding: 0;
         width: 90px;
         height: 100px;
         &:after {
             font-family: "Material Icons";
             font-size: 64px;
             color: @mfp-controls-text-color;
         }
         &-right {
            right: 0;
            &:after  {
                content: '\e315';
            }
         }
         &-left {
             left: 0;
             &:after  {
                 content: '\e314';
             }
         }
     }



// Iframe content type
     .mfp-iframe-holder {
         padding-top: @mfp-iframe-padding-top;
         padding-bottom: @mfp-iframe-padding-top;
         .mfp-content {
             line-height: 0;
             width: 100%;
             max-width: @mfp-iframe-max-width;
         }
         .mfp-close {
             top: -40px;
         }
     }
     .mfp-iframe-scaler {
         width: 100%;
         height: 0;
         overflow: hidden;
         padding-top: @mfp-iframe-ratio * 100%;
         iframe {
             position: absolute;
             display: block;
             top: 0;
             left: 0;
             width: 100%;
             height: 100%;
             box-shadow: @mfp-shadow;
             background: @mfp-iframe-background;
         }
     }



// Image content type

     /* Main image in popup */
     img {
         &.mfp-img {
             width: auto;
             max-width: 100%;
             height: auto;
             display: block;
             line-height: 0;
             -webkit-box-sizing: border-box;
             -moz-box-sizing: border-box;
             box-sizing: border-box;
             padding: @mfp-image-padding-top 0 @mfp-image-padding-bottom;
             margin: 0 auto;
         }
     }

     /* The shadow behind the image */
     .mfp-figure {
         line-height: 0;
         &:after {
             content: '';
             position: absolute;
             left: 0;
             top: @mfp-image-padding-top;
             bottom: @mfp-image-padding-bottom;
             display: block;
             right: 0;
             width: auto;
             height: auto;
             z-index: -1;
             box-shadow: @mfp-shadow;
             background: @mfp-image-background;
         }
         small {
             color: @mfp-caption-subtitle-color;
             display: block;
             font-size: 12px;
             line-height: 14px;
         }
         figure {
             margin: 0;
         }
     }
     .mfp-bottom-bar {
         margin-top: -@mfp-image-padding-bottom + 4;
         position: absolute;
         top: 100%;
         left: 0;
         width: 100%;
         cursor: auto;
     }
     .mfp-title {
         text-align: left;
         line-height: 18px;
         color: @mfp-caption-title-color;
         word-wrap: break-word;
         padding-right: 36px; // leave some space for counter at right side
     }

     .mfp-image-holder {
         .mfp-content {
             max-width: 100%;
         }
     }

     .mfp-gallery {
         .mfp-image-holder {
             .mfp-figure {
                 cursor: pointer;
             }
         }
     }

     @media screen and (max-width: 800px) and (orientation:landscape), screen and (max-height: 300px) {
         /**
          * Remove all paddings around the image on small screen
          */
         .mfp-img-mobile {
             .mfp-image-holder {
                 padding-left: 0;
                 padding-right: 0;
             }
             img {
                 &.mfp-img {
                     padding: 0;
                 }
             }
             .mfp-figure {
                 // The shadow behind the image
                 &:after {
                     top: 0;
                     bottom: 0;
                 }
                 small {
                     display: inline;
                     margin-left: 5px;
                 }
             }
             .mfp-bottom-bar {
                 background: rgba(0,0,0,0.6);
                 bottom: 0;
                 margin: 0;
                 top: auto;
                 padding: 3px 5px;
                 position: fixed;
                 box-sizing: border-box;
                 &:empty {
                     padding: 0;
                 }
             }
             .mfp-counter {
                 right: 5px;
                 top: 3px;
             }
             .mfp-close {
                 top: 0;
                 right: 0;
                 width: 35px;
                 height: 35px;
                 line-height: 35px;
                 background: rgba(0, 0, 0, 0.6);
                 position: fixed;
                 text-align: center;
                 padding: 0;
             }
         }
     }



// Scale navigation arrows and reduce padding from sides
@media all and (max-width: 900px) {
    .mfp-arrow {
        transform: scale(0.75);
    }
    .mfp-arrow-left {
        transform-origin: 0;
    }
    .mfp-arrow-right {
        transform-origin: 100%;
    }
    .mfp-container {
        padding-left: @mfp-popup-padding-left-mobile;
        padding-right: @mfp-popup-padding-left-mobile;
    }
}

// animation

.md-scale {
    &.mfp-bg {
        opacity: 0;
        transition: all 280ms @md_easing;
        &.mfp-ready {
            opacity: 0.3;
        }
        &.mfp-removing {
            opacity: 0;
        }
    }
    &.mfp-wrap {
        .mfp-content {
            opacity: 0;
            transform: scale(0);
            transition: all 280ms @md_easing;
        }
        &.mfp-ready .mfp-content {
            transform: scale(1);
            opacity: 1;
        }
        &.mfp-removing .mfp-content {
            transform: scale(0);
        }
    }
}